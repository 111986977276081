import { useEffect, useState } from 'react';
import { ary, first, includes, partial, isEqual } from 'lodash-es';
import {
  BottomNavigation as BottomNavigationSc,
  BottomNavigationAction,
  Paper,
  styled,
} from '@mui/material';
import {
  Delete as DeleteIcon,
  FactCheckOutlined as FactCheckIcon,
  MoreHoriz as MoreIcon,
  QrCodeScannerOutlined as ScanIcon,
  SupportAgentRounded as SupportAgentIcon,
  VerifiedUserOutlined as ShieldIcon,
} from '@mui/icons-material';
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import { useHistory, useLocation } from 'react-router-dom';
import { GroupType } from 'constants/GroupType';
import { Translate } from 'i18n/Translate';
import { RoutePath } from 'constants/RoutePath';
import { useOrganization } from 'components/organization/OrganizationProvider';
import { SelfCheckinProtectedState } from 'components/auth/SelfCheckinProtectedState';
import { Colour } from 'ui/Colour';
import { getDomain } from 'utils/utils';
import useCustomization from 'hooks/useCustomization';

const StyledPaper = styled(Paper)`
  background-color: white;
  bottom: 0;
  position: fixed;
  width: 100%;
`;

const StyledBottomNavigationAction = styled(BottomNavigationAction)`
  padding-left: 2px;
  padding-right: 2px;
`;

const RedStyledBottomNavigationAction = styled(BottomNavigationAction)`
  color: ${Colour.red};
`;

const INCLUDES_FN = ary(includes, 2);

const ENTRIES = Object.entries({
  [RoutePath.CFP]: partial(INCLUDES_FN, [RoutePath.CFP, RoutePath.CFP_CHECK]),
  [RoutePath.CO2_ORGANIZATION]: partial(INCLUDES_FN, [
    RoutePath.CO2_ORGANIZATION,
  ]),
  [RoutePath.CHECKIN]: (nextValue) =>
    Boolean(
      partial(INCLUDES_FN, [RoutePath.CHECKIN, RoutePath.CHECKIN_APPOINTMENTS])(
        nextValue,
      ) || nextValue?.includes?.(RoutePath.CHECKIN),
    ),
  [RoutePath.MORE]: partial(INCLUDES_FN, [
    RoutePath.MORE,
    RoutePath.PRINT_LABEL,
    RoutePath.PROFILE,
    RoutePath.RESULT_ALT,
  ]),
  [RoutePath.RESULT]: partial(INCLUDES_FN, [RoutePath.RESULT]),
  [RoutePath.CANCEL_ACCOUNT]: partial(INCLUDES_FN, [RoutePath.CANCEL_ACCOUNT]),
});

export const BottomNavigation = () => {
  const history = useHistory();
  const { pathname } = useLocation();
  const [value, setValue] = useState(RoutePath.CFP);
  const {
    current: { groupName },
  } = useOrganization();

  const domain = getDomain();
  const { data: settings } = useCustomization({ domain });

  useEffect(() => {
    const paths = ENTRIES.find(([, matchingFn]) => matchingFn(pathname));

    if (!paths) {
      return;
    }

    setValue(first(paths));
  }, [value, pathname]);

  const onChange = (_, nextValue) => {
    const paths = ENTRIES.find(([, matchingFn]) => matchingFn(nextValue));

    if (!paths) {
      return;
    }

    history.push(first(paths));
    setValue(nextValue);
  };

  const organizationNav = (
    <StyledBottomNavigationAction
      label={<Translate text="co2.navigation.organization" />}
      icon={<ShieldIcon />}
      value={RoutePath.CO2_ORGANIZATION}
    />
  );

  const cfpNav = (
    <StyledBottomNavigationAction
      label={
        isEqual(groupName, GroupType.CO2) ||
        isEqual(groupName, GroupType.HINTERLAND) ? (
          <Translate text="co2.navigation.projects" />
        ) : (
          <Translate text="navigation.cfp" />
        )
      }
      icon={<ShieldIcon />}
      value={RoutePath.CFP}
    />
  );

  const checkInNav = (
    <StyledBottomNavigationAction
      label={<Translate text="navigation.check_in" />}
      icon={<ScanIcon />}
      value={RoutePath.CHECKIN}
    />
  );

  const resultNav = (
    <StyledBottomNavigationAction
      label={<Translate text="navigation.result" />}
      icon={<FactCheckIcon />}
      value={RoutePath.RESULT}
    />
  );

  const supportNav = (
    <StyledBottomNavigationAction
      label={<Translate text="navigation.support" />}
      icon={<SupportAgentIcon />}
      id="custom_intercom_launcher"
    />
  );

  const moreNav = (
    <StyledBottomNavigationAction
      label={<Translate text="navigation.more" />}
      icon={<MoreIcon />}
      value={RoutePath.MORE}
    />
  );

  const cancelAccountNav = (
    <RedStyledBottomNavigationAction
      label={<Translate text="navigation.cancel_account" />}
      icon={<DeleteIcon />}
      value={RoutePath.CANCEL_ACCOUNT}
    />
  );

  const dashboardNav = (
    <StyledBottomNavigationAction
      label={<Translate text="navigation.co2.dashboard" />}
      icon={<DashboardOutlinedIcon />}
      component="a"
      href={`${settings?.formBaseUrl}/dashboard/`}
      rel="noopener noreferrer"
      target="_blank"
    />
  );

  return (
    <StyledPaper>
      <SelfCheckinProtectedState
        otherwise={
          isEqual(groupName, GroupType.CO2) ||
          isEqual(groupName, GroupType.HINTERLAND) ? (
            <BottomNavigationSc value={value} showLabels onChange={onChange}>
              {organizationNav}
              {dashboardNav}
              {supportNav}
              {moreNav}
            </BottomNavigationSc>
          ) : (
            <BottomNavigationSc value={value} showLabels onChange={onChange}>
              {cfpNav}
              {checkInNav}
              {resultNav}
              {supportNav}
              {moreNav}
            </BottomNavigationSc>
          )
        }
      >
        {isEqual(groupName, GroupType.CO2) ||
        isEqual(groupName, GroupType.HINTERLAND) ? (
          <BottomNavigationSc value={value} showLabels onChange={onChange}>
            {organizationNav}
            {dashboardNav}
            {supportNav}
            {moreNav}
          </BottomNavigationSc>
        ) : (
          <BottomNavigationSc value={value} showLabels onChange={onChange}>
            {cfpNav}
            {checkInNav}
            {supportNav}
            {cancelAccountNav}
            {moreNav}
          </BottomNavigationSc>
        )}
      </SelfCheckinProtectedState>
    </StyledPaper>
  );
};
