import { useRef, useEffect, useState, useCallback } from 'react';
import { Box, Button } from '@mui/material';
import { useParams, useHistory } from 'react-router-dom';
import IframeResizer from '@iframe-resizer/react';
import { getOrganizationPath } from 'constants/RoutePath';
import { useOrganization } from 'components/organization/OrganizationProvider';
import { Translate } from 'i18n/Translate';
import { getDomain } from 'utils/utils';
import useCustomization from 'hooks/useCustomization';

export const ProjectEdit = () => {
  const iframeRef = useRef(null);
  const [iframeUrl, setIframeUrl] = useState('');

  const {
    current: { organizationId },
  } = useOrganization();

  const domain = getDomain();
  const { data: settings } = useCustomization({ domain });

  const { externalReference, projectReference } = useParams();

  const { push } = useHistory();

  const switchToProjectsView = useCallback(
    () => push(getOrganizationPath(externalReference)),
    [push, externalReference],
  );

  useEffect(() => {
    // Base URL of the Gravity Form
    const baseUrl = `${settings?.formBaseUrl}/project-bearbeitung/`;

    // Construct URL with parameters
    const urlWithParams = `${baseUrl}?organizationId=${organizationId}&reference=${projectReference}&reload=${Date.now()}`;
    // Set the constructed URL to state
    setIframeUrl(urlWithParams);
  }, [settings?.formBaseUrl, organizationId, projectReference]);

  useEffect(() => {
    // Event listener to receive messages from the iframe
    const handleIframeMessage = (event) => {
      // Ensure the message is from the expected origin
      if (event.origin !== settings?.formBaseUrl) {
        return;
      }

      if (event.data === 'EditProjectFormSubmission') {
        window.scrollTo(0, 0);
      }
    };

    window.addEventListener('message', handleIframeMessage);

    return () => {
      window.removeEventListener('message', handleIframeMessage);
    };
  }, [iframeUrl, settings?.formBaseUrl]);

  const onCloseClick = () => {
    switchToProjectsView();
  };

  return (
    <>
      <Box display="flex" justifyContent="flex-start">
        <Button
          type="submit"
          variant="contained"
          color="primary"
          onClick={onCloseClick}
        >
          <Translate text="action.back" />
        </Button>
      </Box>
      <IframeResizer
        id="projectEdit"
        forwardRef={iframeRef}
        title="projectEdit"
        src={iframeUrl}
        frameBorder="0"
        style={{ width: '100%', height: '500vh' }}
      />
    </>
  );
};
