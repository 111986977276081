import { useQuery } from 'react-query';
import { useApi } from 'hooks/useApi';
import { ERROR_NOTIFICATION } from 'components/notification/notificationType';
import { QueryKeys } from 'constants/QueryKeys';
import { useNotification } from 'components/notification/useNotification';

/**
 * Hook which returns co2 organizations of an organization.
 */
export const useCo2Org = ({ organizationReference }) => {
  const { get } = useApi();
  const { showNotification } = useNotification();

  return useQuery(
    [QueryKeys.FETCH_CO2_ORGANIZATION, organizationReference],
    () => get(`co2/${organizationReference}/organization`),
    {
      onError: () => showNotification(ERROR_NOTIFICATION, 'state.error'),
      refetchOnWindowFocus: false,
    },
  );
};
