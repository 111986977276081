import { Box, Button, styled, Typography } from '@mui/material';
import CreditCardIcon from '@mui/icons-material/CreditCardOutlined';
import { Translate } from 'i18n/Translate';
import { LoginType } from 'constants/LoginType';
import { getDomain } from 'utils/utils';
import useCustomization from 'hooks/useCustomization';
import { MicrosoftLoginButton } from '../microsoft/MicrosoftLoginButton';

const Layout = styled('div')`
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 16px;
  text-align: center;

  h2 {
    margin-bottom: 40px;
  }
`;

export const SelectLoginType = ({ onSelect }) => {
  const domain = getDomain();
  const { data: settings } = useCustomization({ domain });

  return (
    <Layout>
      <Typography variant="h5">
        <Translate text="login.title" />
      </Typography>

      <Box py={2}>
        <Typography variant="body1">
          <Translate text="login.subtitle" />
        </Typography>
      </Box>
      {domain.includes('c02p') ||
      domain.includes('co2p') ||
      domain.includes('hinterland') ? (
        <Box p={2} display="flex" flexDirection="column" alignItems="center">
          <Box mb={3}>
            <MicrosoftLoginButton
              onButtonClick={() => onSelect(LoginType.MICROSOFT)}
            />
          </Box>

          <Box mb={3}>
            <Button
              variant="contained"
              color="primary"
              size="large"
              startIcon={<CreditCardIcon />}
              onClick={() => onSelect(LoginType.KEYCLOAK)}
            >
              <Translate
                text="login.keycloak.title"
                values={{ title: settings?.title }}
              />
            </Button>
          </Box>
        </Box>
      ) : (
        <Box p={2} display="flex" flexDirection="column" alignItems="center">
          <Box mb={3}>
            <Button
              variant="contained"
              color="secondary"
              size="large"
              startIcon={<CreditCardIcon />}
              onClick={() => onSelect(LoginType.IDCARD)}
            >
              <Translate text="login.id_card.title" />
            </Button>
          </Box>
          <Box mb={3}>
            <MicrosoftLoginButton
              onButtonClick={() => onSelect(LoginType.MICROSOFT)}
            />
          </Box>
        </Box>
      )}
    </Layout>
  );
};
