import List from '@mui/material/List';
import ListSubheader from '@mui/material/ListSubheader';
import PrintIcon from '@mui/icons-material/PrintOutlined';
import ProfileIcon from '@mui/icons-material/PersonOutline';
import ResultIcon from '@mui/icons-material/FactCheckOutlined';
import ManageOrganizationIcon from '@mui/icons-material/AdminPanelSettingsOutlined';
import { isEqual } from 'lodash-es';
import { useHistory } from 'react-router-dom';
import { useOrganizationSlug } from 'hooks/useOrganizationSlug';
import { useOrganization } from 'components/organization/OrganizationProvider';
import { Translate } from 'i18n/Translate';
import { GroupType } from 'constants/GroupType';
import { SelfCheckinProtectedState } from 'components/auth/SelfCheckinProtectedState';
import { RoutePath } from 'constants/RoutePath';
import { MoreListItem } from './MoreListItem';
import { MoreLogoutButton } from './MoreLogoutButton';

export const More = () => {
  const history = useHistory();
  const { withOrganizationSlug } = useOrganizationSlug();
  const {
    current: { groupName },
  } = useOrganization();

  return isEqual(groupName, GroupType.CO2) ||
    isEqual(groupName, GroupType.HINTERLAND) ? (
    <>
      <ListSubheader disableSticky>
        <Translate text="more.menu.subtitle.management" />
      </ListSubheader>

      <MoreListItem
        icon={<ProfileIcon />}
        primaryText="more.menu.profile"
        secondaryText="more.menu.profile.info"
        onClick={() => {
          history.push(RoutePath.PROFILE);
        }}
      />

      <SelfCheckinProtectedState
        otherwise={
          <MoreListItem
            icon={<ManageOrganizationIcon />}
            primaryText="more.menu.manage_organization"
            secondaryText="more.menu.manage_organization.info"
            onClick={() => {
              history.push(withOrganizationSlug(RoutePath.ADMIN));
            }}
          />
        }
      />

      <MoreLogoutButton />
    </>
  ) : (
    <List
      subheader={
        <SelfCheckinProtectedState
          otherwise={
            <ListSubheader disableSticky>
              <Translate text="more.menu.subtitle.actions" />
            </ListSubheader>
          }
        />
      }
    >
      <SelfCheckinProtectedState
        otherwise={
          <MoreListItem
            icon={<PrintIcon />}
            primaryText="more.menu.print"
            secondaryText="more.menu.print.info"
            onClick={() => {
              history.push(RoutePath.PRINT_LABEL);
            }}
          />
        }
      />

      <SelfCheckinProtectedState
        otherwise={
          <MoreListItem
            icon={<ResultIcon />}
            primaryText="more.menu.result"
            secondaryText="more.menu.result.info"
            onClick={() => {
              history.push(RoutePath.RESULT_ALT);
            }}
          />
        }
      />

      <ListSubheader disableSticky>
        <Translate text="more.menu.subtitle.management" />
      </ListSubheader>

      <MoreListItem
        icon={<ProfileIcon />}
        primaryText="more.menu.profile"
        secondaryText="more.menu.profile.info"
        onClick={() => {
          history.push(RoutePath.PROFILE);
        }}
      />

      <SelfCheckinProtectedState
        otherwise={
          <MoreListItem
            icon={<ManageOrganizationIcon />}
            primaryText="more.menu.manage_organization"
            secondaryText="more.menu.manage_organization.info"
            onClick={() => {
              history.push(withOrganizationSlug(RoutePath.ADMIN));
            }}
          />
        }
      />

      <MoreLogoutButton />
    </List>
  );
};
