import { useCallback, useMemo } from 'react';
import { Box, Paper, Typography, IconButton } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { size } from 'lodash-es';
import { IntlDateTime } from 'i18n/IntlDateTime';
import { Translate } from 'i18n/Translate';
import { SKIP_RENDER } from 'constants/semanticConstants';
import { snakeToCamelCase } from 'utils/utils';

const Row = ({ project, onRowClick, onProjectEdit }) => {
  const { createdAt, projectInfo, projectName, projectType } = useMemo(
    () => snakeToCamelCase(project),
    [project],
  );

  const onClick = useCallback(() => onRowClick(project), [project, onRowClick]);

  const onEdit = useCallback(() => {
    onProjectEdit(project);
  }, [project, onProjectEdit]);

  return (
    <TableRow hover>
      <TableCell onClick={onClick}>{projectName}</TableCell>

      <TableCell onClick={onClick}>{projectType}</TableCell>

      <TableCell onClick={onClick}>
        <IntlDateTime date={projectInfo.startDate} />
      </TableCell>

      <TableCell onClick={onClick}>
        <IntlDateTime date={createdAt} />
      </TableCell>
      <TableCell>
        <IconButton
          style={{ marginLeft: '25px', height: '20px', width: '20px' }}
          size="large"
          onClick={onEdit}
        >
          <EditIcon style={{ height: '20px', width: '20px' }} />
        </IconButton>
      </TableCell>
    </TableRow>
  );
};
export const ProjectList = ({ projects, onRowClick, onProjectEdit }) => {
  return size(projects) ? (
    <Box width={1} mt={3}>
      <Box p={1} pl={2}>
        <Typography variant="subtitle2">
          <Translate
            text="co2.projects.count"
            values={{ count: projects.length }}
          />
        </Typography>
      </Box>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <Translate text="admin.tests.table.project.name" />
              </TableCell>

              <TableCell>
                <Translate text="admin.tests.table.project.type" />
              </TableCell>

              <TableCell>
                <Translate text="admin.tests.table.project.startDate" />
              </TableCell>

              <TableCell>
                <Translate text="admin.tests.table.organization.date" />
              </TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {projects.map((project) => (
              <Row
                key={project.test_code}
                project={project}
                onRowClick={onRowClick}
                onProjectEdit={onProjectEdit}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  ) : (
    SKIP_RENDER
  );
};
