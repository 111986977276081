import { Switch } from 'react-router-dom';
import { ReactQueryDevtools } from 'react-query/devtools';
import { Box } from '@mui/material';
import { isEqual } from 'lodash-es';
import { BottomNavigation } from 'ui/BottomNavigation';
import { ProtectedRoute } from 'components/auth/ProtectedRoute';
import { Intercom } from 'tools/intercom/Intercom';
import { AuthSetupRedirect } from 'auth/AuthSetupRedirect';
import { Route } from 'components/auth/Route';
import { AppInterceptor } from 'interceptors';
import { Roles } from 'constants/Roles';
import { RoutePath } from 'constants/RoutePath';
import { GroupType } from 'constants/GroupType';
import { useOrganization } from 'components/organization/OrganizationProvider';
import { View as CheckIn } from './CheckIn';
import { Result } from './result/Result';
import { CustomerProfile } from './profile/CustomerProfile';
import { More } from './more/More';
import { PrintLabel } from './test/PrintLabel';
import { ResultAlt } from './result/ResultAlt';
import { CfpContainer } from './cfp/CfpContainer';
import { View as CancelAccountContainer } from './CancelAccount';
import { OrganizationOverview } from './co2/OrganizationOverview';

export const AuthenticatedCustomerApp = () => {
  const {
    current: { groupName },
  } = useOrganization();

  return (
    <>
      <Box display="flex" flexDirection="column" height="100vh">
        <Box pb={7}>
          <Switch>
            <ProtectedRoute
              component={CheckIn}
              path={RoutePath.CHECKIN_APPOINTMENT}
              role={[Roles.TEST_CHECKIN, Roles.VACCINATION_CHECKIN]}
            />
            <ProtectedRoute
              component={PrintLabel}
              path={RoutePath.PRINT_LABEL}
              role={[Roles.TEST_CHECKIN, Roles.TEST_RESULT]}
            />
            <ProtectedRoute
              component={ResultAlt}
              path={RoutePath.RESULT_ALT}
              role={Roles.TEST_RESULT}
            />
            <ProtectedRoute
              component={Result}
              path={RoutePath.RESULT}
              role={Roles.TEST_RESULT}
            />

            <Route
              component={AuthSetupRedirect}
              path={RoutePath.OAUTH_REDIRECT}
            />
            <Route component={CfpContainer} path={RoutePath.CFP} />
            <Route
              component={OrganizationOverview}
              path={RoutePath.CO2_ORGANIZATION}
            />
            <Route component={CustomerProfile} path={RoutePath.PROFILE} />
            <Route
              component={CancelAccountContainer}
              path={RoutePath.CANCEL_ACCOUNT}
            />
            <Route component={More} path={RoutePath.MORE} />

            {/* Invoked only when the previous routes do not have any match */}
            {isEqual(groupName, GroupType.CO2) ||
            isEqual(groupName, GroupType.HINTERLAND) ? (
              <AppInterceptor fallbackTargetPath={RoutePath.CO2_ORGANIZATION} />
            ) : (
              <AppInterceptor fallbackTargetPath={RoutePath.CFP} />
            )}
          </Switch>
        </Box>
        <BottomNavigation />
      </Box>

      <Intercom useCustomLauncher />
      <ReactQueryDevtools initialIsOpen={false} />
    </>
  );
};
