import { useEffect, useState } from 'react';
import Oidc from 'oidc-client';
import { isEqual } from 'lodash-es';
import useCustomization from 'hooks/useCustomization';
import { getDomain } from 'utils/utils';
import { ResponseValidatorCtor } from '../microsoft/MicrosoftOidcResponseValidator';
import { OidcProvider } from './Oidc';

export const useOidc = (provider, redirectUri) => {
  const [manager, setManager] = useState();
  const [setupFinished, setSetupFinished] = useState(false);

  const domain = getDomain();
  const { data: settings } = useCustomization({ domain });

  useEffect(() => {
    if (manager) {
      return;
    }
    if (isEqual(provider, OidcProvider.MICROSOFT)) {
      setManager(
        new Oidc.UserManager({
          authority: 'https://login.microsoftonline.com/common/v2.0',
          client_id: process.env.REACT_APP_MS_OIDC_CLIENTID,
          loadUserInfo: false,
          post_logout_redirect_uri: `${window.location.origin}/admin`,
          redirect_uri: `${window.location.origin}/${redirectUri}`,
          response_type: 'id_token',
          ResponseValidatorCtor,
          scope: 'openid email',
        }),
      );
    } else if (isEqual(provider, OidcProvider.KEYCLOAK)) {
      setManager(
        new Oidc.UserManager({
          authority: settings?.keycloakAuthority, // process.env.REACT_APP_KEYCLOAK_OIDC_AUTHORITY,
          client_id: settings?.keycloakClientID, // process.env.REACT_APP_KEYCLOAK_OIDC_CLIENTID,
          loadUserInfo: false,
          post_logout_redirect_uri: `${window.location.origin}/admin`,
          redirect_uri: `${window.location.origin}/${redirectUri}`,
          response_type: 'code',
          scope: 'openid email',
        }),
      );
    }

    setSetupFinished(true);
  }, [
    provider,
    manager,
    redirectUri,
    settings?.keycloakAuthority,
    settings?.keycloakClientID,
  ]);

  const signInRedirectCallback = () => manager.signinRedirectCallback();
  const getUser = () => manager?.getUser();
  const signInRedirect = (data) => manager.signinRedirect(data);

  return {
    getUser,
    setupFinished,
    signInRedirect,
    signInRedirectCallback,
  };
};
