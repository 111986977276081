import { useEffect, useState } from 'react';
import { Switch, useHistory } from 'react-router-dom';
import queryString from 'query-string';
import { v4 as uuidv4 } from 'uuid';
import { Box } from '@mui/material';
import jwtSign from 'jwt-encode';
import { MicrosoftLogin } from 'auth/microsoft/MicrosoftLogin';
import { useAuth } from 'auth/AuthContext';
import { IdCardLogin } from 'auth/IdCardLogin';
import { FullPageSpinner } from 'components/FullPageSpinner';
import { useOrganization } from 'components/organization/OrganizationProvider';
import { SelectLoginType } from 'auth/login/SelectLoginType';
import { LoginType } from 'constants/LoginType';
import { LoginAuthRedirect } from 'auth/login/LoginAuthRedirect';
import { Route } from 'components/auth/Route';
import { usePendingPath } from 'hooks/usePendingPath';
import useLogin from 'auth/useLogin';
import { AppLogo } from 'components/AppLogo';
import { RoutePath } from 'constants/RoutePath';
import { EMPTY_OBJECT, EMPTY_STRING } from 'constants/semanticConstants';
import { RouteParam } from 'constants/RouteParam';
import { KeycloakLogin } from 'auth/keycloak/KeycloakLogin';
import { getDomain } from 'utils/utils';
import useCustomization from 'hooks/useCustomization';

export const UnauthenticatedApp = () => {
  const {
    location: { search, pathname },
    replace,
  } = useHistory();
  const [loginType, setLoginType] = useState();
  const { onLoginSucceeded, onUserContextUpdate, isLogoutFlow } = useAuth();
  const { setOrganizations } = useOrganization();
  const { setPendingPath } = usePendingPath();
  const maybeId = queryString.parse(search)?.[RouteParam.ID];
  const [identity, setIdentity] = useState(maybeId);

  const domain = getDomain();
  const { data: settings, isLoading: isSettingsLoading } = useCustomization({
    domain,
  });

  useEffect(() => {
    if (!pathname?.includes(RoutePath.LOGIN)) {
      if (!isLogoutFlow) {
        setPendingPath(`${pathname}${search}`);
      }

      replace(RoutePath.LOGIN);
    }

    if (!maybeId) {
      return;
    }
    setLoginType(LoginType.IDCARD);
  }, [
    isLogoutFlow,
    maybeId,
    onUserContextUpdate,
    pathname,
    replace,
    search,
    setPendingPath,
  ]);

  /**
   * Enables the auth mode for the user.
   *
   * The arguments are applied as the follows: `data: TData, variables: TVariables, context?:
   * TContext` which means that the parameters are echoed as well. (see docs from `react-query`)
   *
   * @param receivedData the data received from the API upon calling login.
   * @param inputVariables the input given by the app.
   */
  const enableAuthenticationMode = (receivedData, inputVariables) => {
    const {
      account,
      activated: isLoginMethodActivated,
      organizations,
      token,
    } = receivedData;
    const { key, type } = inputVariables;

    setOrganizations(organizations);
    onLoginSucceeded({ token }, account);

    // ---
    // Temporary hashed PIN the user has chosen. Gets wiped after the activation.
    const keyContext = isLoginMethodActivated
      ? EMPTY_OBJECT
      : {
          encryptedKey: jwtSign(key || EMPTY_STRING, uuidv4()),
        };

    onUserContextUpdate({
      ...keyContext,
      identity,
      isLoginMethodActivated,
      type,
    });
  };

  const redirectToLoginPage = () => replace(RoutePath.LOGIN);

  const { isLoading, mutate: attemptToLogin } = useLogin({
    onSuccess: enableAuthenticationMode,
    onError: redirectToLoginPage,
  });

  const renderLoginRoutes = () => {
    switch (loginType) {
      case LoginType.IDCARD: {
        return (
          <IdCardLogin
            onLoginReady={attemptToLogin}
            onDiscoverIdentity={setIdentity}
          />
        );
      }
      case LoginType.MICROSOFT: {
        return <MicrosoftLogin />;
      }
      case LoginType.KEYCLOAK: {
        return <KeycloakLogin />;
      }
      default:
        return <SelectLoginType onSelect={setLoginType} />;
    }
  };

  return isLoading || isSettingsLoading ? (
    <FullPageSpinner />
  ) : (
    <>
      <Box p={2} display="flex" justifyContent="center">
        <AppLogo />
      </Box>

      <Switch>
        <Route path={RoutePath.LOGIN_WITH_REDIRECT}>
          <LoginAuthRedirect
            onLoginReady={attemptToLogin}
            groupName={settings?.groupName}
          />
        </Route>

        <Route path={RoutePath.LOGIN}>{renderLoginRoutes()}</Route>
      </Switch>
    </>
  );
};
