export const QueryKeys = {
  CHECKIN_STATE: 'CHECKIN_STATE',
  FETCH_HEALTH_AUTHORITY_SETTINGS: 'FETCH_HEALTH_AUTHORITY_SETTINGS',
  FETCH_ORGANIZATION: 'FETCH_ORGANIZATION',
  FETCH_TEST_TYPES: 'FETCH_TEST_TYPES',
  FETCH_VACCINATION_VALUE_SET: 'FETCH_VACCINATION_VALUE_SET',
  TOKEN_REFRESH: 'TOKEN_REFRESH',

  FETCH_TEST: 'FETCH_TEST',
  FETCH_TESTS: 'FETCH_TESTS',
  FETCH_GLOBAL_TESTS: 'FETCH_GLOBAL_TESTS',
  LOOKUP_TEST: 'LOOKUP_TEST',

  CREATE_REPORT_SETTINGS: 'CREATE_REPORT_SETTINGS',
  DELETE_REPORT_SETTINGS: 'DELETE_REPORT_SETTINGS',
  FETCH_REPORTS: 'FETCH_REPORTS',
  FETCH_REPORTS_SETTINGS: 'FETCH_REPORTS_SETTINGS',
  FETCH_REPORTS_SETTINGS_TEMPLATES: 'FETCH_REPORTS_SETTINGS_TEMPLATES',
  UPDATE_REPORT_SETTINGS: 'UPDATE_REPORT_SETTINGS',

  FETCH_HEALTH_STATUS: 'FETCH_HEALTH_STATUS',

  CREATE_COMMUNICATION_TEXT: 'CREATE_COMMUNICATION_TEXT',
  DELETE_COMMUNICATION_TEXT: 'DELETE_COMMUNICATION_TEXT',
  FETCH_COMMUNICATION_TEXTS: 'FETCH_COMMUNICATION_TEXTS',
  FETCH_COMMUNICATION_TEXTS_TEMPLATES: 'FETCH_COMMUNICATION_TEXTS_TEMPLATES',
  UPDATE_COMMUNICATION_TEXT: 'UPDATE_COMMUNICATION_TEXT',

  FETCH_APPOINTMENT: 'FETCH_APPOINTMENT',
  FETCH_APPOINTMENTS: 'FETCH_APPOINTMENTS',
  FETCH_CHECK_IN_OPTIONS: 'FETCH_CHECK_IN_OPTIONS',

  FETCH_DCC: 'FETCH_DCC',
  FETCH_DCC_SETTINGS: 'FETCH_DCC_SETTINGS',
  FETCH_DCC_TEST_MANUFACTURERS: 'FETCH_DCC_TEST_MANUFACTURERS',

  FETCH_ACCOUNT: 'FETCH_ACCOUNT',
  FETCH_ACCOUNTS: 'FETCH_ACCOUNTS',
  FETCH_SINGLE_ACCOUNT: 'FETCH_SINGLE_ACCOUNT',
  FETCH_DANGLING_ACCOUNTS_TO_SEND_DELETION_WARN:
    'FETCH_DANGLING_ACCOUNTS_TO_SEND_DELETION_WARN',
  FETCH_DANGLING_ACCOUNTS_TO_DELETE: 'FETCH_DANGLING_ACCOUNTS_TO_DELETE',
  FETCH_ACCESSTOKEN: 'FETCH_ACCESSTOKEN',

  FETCH_STANDARD_TEST_TYPES: 'FETCH_STANDARD_TEST_TYPES',
  FETCH_TEST_TYPES_SETTINGS: 'FETCH_TEST_TYPES_SETTINGS',

  FETCH_CO2_PROJECTS: 'FETCH_CO2_PROJECTS',
  FETCH_CO2_CERTIFICATES: 'FETCH_CO2_CERTIFICATES',
  FETCH_CO2_CERTIFICATE: 'FETCH_CO2_CERTIFICATE',
  FETCH_CO2_PROJECT: 'FETCH_CO2_PROJECT',
  FETCH_CO2_DOCUMENTS: 'FETCH_CO2_DOCUMENTS',
  FETCH_CO2_ORGANIZATIONS: 'FETCH_CO2_ORGANIZATIONS',
  FETCH_CUSTOMIZATION: 'FETCH_CUSTOMIZATION',
  FETCH_CO2_ORGANIZATION: 'FETCH_CO2_ORGANIZATION',
};
