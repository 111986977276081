import { useIntl } from 'react-intl';
import { styled } from '@mui/material';
import { getDomain } from 'utils/utils';
import useCustomization from 'hooks/useCustomization';

const StyledImg = styled('img')`
  max-height: 60px;
`;

export const AppLogo = ({ className }) => {
  const { formatMessage } = useIntl();

  const domain = getDomain();
  const { data: settings } = useCustomization({ domain });

  return (
    <StyledImg
      className={className}
      src={`${process.env.PUBLIC_URL}/${settings?.logoUrl}`}
      alt={formatMessage({ id: 'alt.app_icon' })}
    />
  );
};
