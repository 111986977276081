import { useQuery } from 'react-query';
import { useApi } from 'hooks/useApi';
import { ERROR_NOTIFICATION } from 'components/notification/notificationType';
import { QueryKeys } from 'constants/QueryKeys';
import { useNotification } from 'components/notification/useNotification';

/**
 * Hook which returns the settings for a domain.
 */
const useCustomization = ({ domain }) => {
  const { get } = useApi();
  const { showNotification } = useNotification();

  return useQuery(
    QueryKeys.FETCH_CUSTOMIZATION,
    () => get(`customize/layout/${domain}`),
    {
      onError: () => showNotification(ERROR_NOTIFICATION, 'state.error'),
      refetchOnWindowFocus: false,
    },
  );
};

export default useCustomization;
