import { useCallback } from 'react';
import { Box, styled, Typography } from '@mui/material';
import ChevronRightIcon from '@mui/icons-material/ChevronRightRounded';
import { useHistory, useParams } from 'react-router-dom';
import { SelfCheckinProtectedState } from 'components/auth/SelfCheckinProtectedState';
import { RedButton } from 'ui/theme/button/RedButton';
import { BackdropSpinner } from 'components/BackdropSpinner';
import {
  getProjectPath,
  getProjectRegisterPath,
  getProjectEditPath,
} from 'constants/RoutePath';
import { CustomizableContent } from 'components/auth/CustomizableContent';
import { KnownOrganizationId } from 'constants/KnownOrganizationId';
import { useCo2Projects } from 'hooks/useCo2Projects';
import { ProjectList } from 'customer/co2/ProjectsList';
import { Co2PdfUpload } from 'customer/co2/Co2PdfUpload';
import { useCo2Org } from 'hooks/useCo2Organization';

const Layout = styled('section')`
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const Project = () => {
  const { push } = useHistory();
  const { externalReference } = useParams();

  const { data: projects, isLoading } = useCo2Projects({
    identity: externalReference,
  });

  const { data: organization, isLoading: isOrgLoading } = useCo2Org({
    organizationReference: externalReference,
  });

  const switchToProjectDetailsView = useCallback(
    ({ external_reference: exReference }) => push(getProjectPath(exReference)),
    [push],
  );

  const switchToProjectRegisterView = useCallback(
    () => push(getProjectRegisterPath(externalReference)),
    [push, externalReference],
  );

  const switchToProjectEdit = useCallback(
    (project) => {
      const projectReference = project.external_reference;
      push(getProjectEditPath(externalReference, projectReference));
    },
    [push, externalReference],
  );

  const projectSection = (
    <Box
      alignItems="center"
      display="flex"
      justifyContent="center"
      flexDirection="column"
      mt={6}
    >
      <Box>
        <Typography variant="h6">
          {organization?.organizationInfo.name}
        </Typography>
      </Box>
      <Box mb={4} mt={1}>
        <ProjectList
          projects={projects}
          onRowClick={switchToProjectDetailsView}
          onProjectEdit={switchToProjectEdit}
        />
      </Box>

      <div>
        <RedButton
          component="a"
          onClick={switchToProjectRegisterView}
          rel="noopener noreferrer"
          variant="contained"
          text="co2.project.register"
          endIcon={<ChevronRightIcon />}
        />
      </div>
      <Box mt={2}>
        <Co2PdfUpload type="organization" />
      </Box>
    </Box>
  );

  return (
    <>
      <BackdropSpinner isOpen={isLoading || isOrgLoading} />
      <Layout>
        <SelfCheckinProtectedState otherwise={projectSection}>
          <CustomizableContent
            organizationId={KnownOrganizationId.SGQUZPUR}
            otherwise={projectSection}
          />
        </SelfCheckinProtectedState>
      </Layout>
    </>
  );
};
