import { useRef, useEffect, useState, useCallback } from 'react';
import { Box, Button } from '@mui/material';
import { useHistory } from 'react-router-dom';
import IframeResizer from '@iframe-resizer/react';
import { getOrganizationsPath } from 'constants/RoutePath';
import { getDomain } from 'utils/utils';
import useCustomization from 'hooks/useCustomization';
import { useOrganization } from 'components/organization/OrganizationProvider';
import { Translate } from 'i18n/Translate';

export const OrganizationRegister = () => {
  const iframeRef = useRef(null);
  const [iframeUrl, setIframeUrl] = useState('');

  const {
    current: { organizationId },
  } = useOrganization();

  const domain = getDomain();
  const { data: settings } = useCustomization({ domain });

  const { push } = useHistory();

  const switchToOrganizationView = useCallback(
    () => push(getOrganizationsPath()),
    [push],
  );

  useEffect(() => {
    // Base URL of the Gravity Form

    const baseUrl = `${settings?.formBaseUrl}${settings?.organizationFormPath}`;

    // Construct URL with parameters
    const urlWithParams = `${baseUrl}?organizationId=${organizationId}`;

    // Set the constructed URL to state
    setIframeUrl(urlWithParams);
  }, [organizationId, settings?.formBaseUrl, settings?.organizationFormPath]);

  useEffect(() => {
    // Event listener to receive messages from the iframe
    const handleIframeMessage = (event) => {
      // Ensure the message is from the expected origin
      if (event.origin !== settings?.formBaseUrl) {
        return;
      }

      if (event.data === 'OrganizationFormSubmission') {
        window.scrollTo(0, 0);
      }
    };

    window.addEventListener('message', handleIframeMessage);

    return () => {
      window.removeEventListener('message', handleIframeMessage);
    };
  }, [settings?.formBaseUrl]);

  return (
    <>
      <Box display="flex" justifyContent="flex-start">
        <Button
          type="submit"
          variant="contained"
          color="primary"
          onClick={switchToOrganizationView}
        >
          <Translate text="action.back" />
        </Button>
      </Box>
      <IframeResizer
        id="org"
        forwardRef={iframeRef}
        title="organization"
        src={iframeUrl}
        frameBorder="0"
        scrolling="no"
        style={{ width: '100%', height: '200vh' }}
      />
    </>
  );
};
