import { useEffect } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { FullPageSpinner } from 'components/FullPageSpinner';
import { logToConsole } from 'utils/utils';
import { useOidc } from 'auth/oidc/OidcHook';

export const LoginAuthRedirect = ({ onLoginReady, groupName }) => {
  const {
    params: { provider },
  } = useRouteMatch();
  const { signInRedirectCallback, setupFinished } = useOidc(provider);

  useEffect(() => {
    if (!setupFinished) {
      return;
    }

    signInRedirectCallback()
      .then(({ id_token: identity }) =>
        onLoginReady({
          identity,
          type: provider,
          groupType: groupName,
        }),
      )
      .catch(logToConsole);
  }, [
    setupFinished,
    onLoginReady,
    provider,
    signInRedirectCallback,
    groupName,
  ]);

  return <FullPageSpinner />;
};
